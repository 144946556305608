.user-overview-tab .card {
  height: 18rem;
}

div.user-access-area {
  margin-left: 0;
  display: block !important;
  width: 30%;
  border: 1px dotted lightgray;
  padding: 0.5rem;
}

div.user-access-actions {
  margin: 0.5rem 0;
}

.pokelist-header {
  margin: 1rem;
}

h1#pokelist-header {
  margin-left: 1rem;
}

/* Apply left padding to sublinks
  so they appear under parent link */
.nav-sub-link {
  padding-left: 1rem;
}
.tabButton {
  overflow: hidden;
  text-align: center;
  background-color: inherit;
  color: #e69e7b;
  font-size: 16px;
  font-weight: bolder;
}

.tabSection {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  width: 100%;
}

.activeTab {
  background-color: #e69e7b;
  color: white;
}

.tabButton:hover {
  background-color: #ddd;
  color: #e69e7b;
}

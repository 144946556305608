.lesson-type-text {
  color: white !important;
}

.back-button {
  cursor: pointer;
  margin-left: 1rem;
  color: rgb(99, 97, 97);
}

.table-title {
  margin-bottom: 1rem;
  color: rgb(99, 97, 97);
  margin-top: 1rem;
}

p {
  font-size: 1.2rem;
}

.lesson-viewer table thead {
  background-color: rgb(255, 249, 243);
}

.lesson-row {
  cursor: pointer;
}

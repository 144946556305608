.prompt {
  background-color: lightgray;
  margin-bottom: 0rem;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  font-size: 12pt;
}

.response {
  margin-bottom: 8px;
  font-size: 12pt;
}

.viewMore {
  cursor: pointer !important;
}
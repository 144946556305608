.bg {
    background-color: #001f3f
}
.norms {
    /* color: #175778; */
    /* font-family: "Roboto"; */
    font-size: 16px;
    /* line-height: 5pt; */
    /* font-weight: 100;				 */
}
.TitleA {
    color: #175778;
    /* font-family: "Roboto"; */
    font-size: 14pt;
    /* line-height: 5pt; */
    /* font-weight: 100;				 */
}

.TitleC {
    /* color: #175778; */
    /* font-family: "Roboto"; */
    font-size: 28px;
    color: #636161;
    /* line-height: 1.5px; */
    /* line-height: 8pt; */
    /* font-weight: 100;				 */
}

.TitleF {
    /* color: #175778; */
    /* font-family: "Roboto"; */
    font-size: 40px;
    color: #636161;
}
.TitleCc {
    color: #175778;
    font-size: 24px;
    line-height: 1px;

}
.TitleCb {
    color: #175778;
    font-size: 14px;
    line-height: 1px;

}
.TitleD {
    color: #175778;
    /* font-family: "Roboto"; */
    font-size: 16pt;
    /* line-height: 5pt; */
    /* font-weight: 100;				 */
}
.TitleDB {
    color: #636161;
    /* font-family: "Roboto"; */
    /* text-decoration: None; */
    font-size: 17px;
    line-height: .9px;
    font-weight: 500;
}
.TitleDBB {
    color: #636161;
    /* font-family: "Roboto"; */
    font-size: 16px;
    line-height: 1px;
    font-weight: 500;
}
.ACT {
    color: #003765;
}

.ST {
    color: #B1394A;
}

.areports_table table {
    height: 70%;
    width: 80%;
    border-spacing: 1px;
    border-collapse: collapse;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1px;
    /* border: 1px solid black; */
}
.reports_table table {
    height: 50%;
    /* width: 80%; */
    border-spacing: 1px;
    border-collapse: collapse;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1px;
    /* border: 1px solid black; */
}
.reports_table004 table {
    height: 50%;
    /* width: 80%; */
    border-spacing: 1px;
    border-collapse: collapse;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1px;
    /* border: 1px solid black; */
}
.reports_table tr {
    padding: 0px;

}
.reports_table004 tr {
    padding: 0px;

}
.areports_table tr {
    padding: 0px;
    /* font-family: "Roboto"; */
    font-size: 12px;
}

.reports_table td {
    /* color: #175778; */
    /* font-family: "Roboto"; */
    font-size: 10px;
    /* line-height: 5pt; */
    /* height: 1px; */
    /* height: 2px; */
    font-weight: 400;
}
.reports_tableX tr {
    padding: 0px;
}
.reports_tableX td {
    border-right: 1px solid #CED4DA;
    border-left: 1px solid #CED4DA;
}
/* ,   borderRightWidth: "1px", borderRightColor:"#aaaaaa", borderRightStyle:'solid'  */
.reports_tableX tr:first-child td {
    border-top: 0;
  }
  .reports_tableX tr td:first-child {
    border-left: 0;
  }
  .reports_tableX tr:last-child td {
    border-bottom: 0;
  }
  .reports_tableX tr td:last-child {
    border-right: 0;
  }
  .reports_tableX tr:last-child {
    border-bottom: 1px solid #CED4DA;
  }
  
.reports_table004 td {
    /* color: #175778; */
    /* font-family: "Roboto"; */
    font-size: 10px;
    /* line-height: 1.2px; */
    /* height: 1px; */
    /* height: 2px; */
    font-weight: 400;
}
.reports_table004 th {
    /* font-family: "Roboto"; */
    font-size: 11px;
    font-weight: 500;
    /* height: 1px; */
}
.reports_table th {
    /* font-family: "Roboto"; */
    font-size: 11px;
    font-weight: 500;
    /* height: 1px; */
}
.article {
    float: left;
    padding-left: 20px;
    width: 90%;
    margin-left: 10%;
}
.articleRight {
    float: right;
    padding-right: 10px;
    width: 20%;
    /* margin-left: 10%; */
}

.articleB {
    float: left;
    padding: 15px;
    width: 90%;
    margin-left: 10%;
}
.sidenav {
    float: left;
    height: 100%;
    width: 35%;
    padding-right: 22px;
    padding-left: 8px;
    position: absolute;
}
.sidenavB {
    float: left;
    height: 100%;
    width: 10%;
    padding: 20px;
    position: absolute;
}
.sidenavRight {
    float: right;
    height: 100%;
    width: 35%;
    padding-right: 8px;
    padding-left: 22px;
    position: absolute;
}


.dropdownA {
    position: relative;
    display: inline-block;
}

.twoArm_table th {background-color: #FFFFFF;}
/* .twoArm_table tr:nth-child(4n) {background-color: #941751;} */
.twoArm_tableB th {background-color: #FFFFFF;}


.twoArm_table tr:nth-child(even) {background-color: #FBFAFE;}
.twoArm_table tr:nth-child(odd) {background-color: #F6F8FE;}

.twoArm_tableB tr:nth-child(even) {background-color: #FFFFFF;}
.twoArm_tableB tr:nth-child(odd) {background-color: #F9F8FA;}
/* #F4F4F4 #F9F8FA */

.threeArm_table th {background-color: #FFFFFF;}
.threeArm_table tr:nth-of-type(3n+2) {background-color: #FBFAFE;}
.threeArm_table tr:nth-of-type(3n+1) {background-color: #F6F8FE;}
.threeArm_table tr:nth-of-type(3n) {background-color: #FFFFFF;}

.consort_table table{
    background-color: "white";
    width: 20%;
    padding-left: 70px;
    margin-left:50;
    border: "1px solid black";
    height: 50%;
    border-spacing: 1px;
    border-collapse: collapse;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1px;
}
.consort_table th{
    background-color: "#76A0C0";
    color: "white";
    border: "1px solid black";
    font-weight: 500;
    font-size: 11px;
    border-collapse: collapse;
}
.consort_table td{
    background-color: "white";
    border: "1px solid black";
    font-size: 10px;
    font-weight: 400;
}
.consort_table thB{
    background-color: "#white";
    color: "white";
    border: "1px solid black";
    font-weight: 500;
    font-size: 11px;
}
.fubutton {
    display: inline-block;
    color: white;
    text-align: left;
    /* padding: 14px 16px; */
    text-decoration-line: none;
}
li a, .dropbtn {
    display: inline-block;
    color: white;
    text-align: left;
    /* padding: 14px 16px; */
    text-decoration-line: none;
}

li a:hover, .dropdown:hover .dropbtn {
    background-color:#18BC9C;
    text-decoration-line: none;
}

li.dropdown {
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    /* background-color: #f9f9f9; */
    min-width: 260px;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 1;
    color: #636161;
    /* font-family: "Roboto"; */
    font-size: 14px;
    line-height: 1px;
    font-weight: 500;
}

.dropdown-content a {
    color: black;
    /* padding: 12px 16px; */
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {background-color: #f1f1f1;}

.dropdown:hover .dropdown-content {
    display: block;}


consortBB {
    background-image : "consort_diagram004B.png";
      }

.square {
width: 24px;
height: 24px;
background-color: #9C4341

}

/* @page {  
      size: 8.5in 11in;
      margin: 1in;
      max-height: 10in;
      max-width: 8.5in; 
    } */
/*@media print {    .no-print, .no-print *
    {
        display: none !important;
    }
}*/

  

 page_{
    page-break-after: always;
    break-after: always;
}
     
   Container {
    page-break-inside: avoid;
  }
.table-title {
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 1rem;
  color: rgb(99, 97, 97);
  margin-top: 1rem;
}

.playlist-card {
  margin-top: 2rem;
}

.sessions-creation-actions {
  text-align: right;
}

.close-btn {
  text-align: right;
}

h4.detail-card-header {
  font-size: 1.2rem;
  color: rgb(185 157 141);
}

.detail-card-subtext {
  color: gray;
  border-color: transparent;
}

.session-task-title {
  font-size: 1rem;
}

.session-task-subtitle {
  font-size: 1rem;
}

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

.navbar-brand {
  color: #fff !important;
}

.navbar {
  background-color: #e69e7b;
  border-bottom: 1px solid #e6e3e3;
}

.navbar-nav>.nav-link,
.dropdown-toggle.nav-link {
  color: #fff !important;
}

.navbar-nav>button {
  color: #fff !important;
  border-color: #fff !important;
}

.users {
  overflow: auto;
}

.jumbotron {
  padding: 0.5rem 1rem !important;
  background-color: #fff !important;
}

.sign-in {
  background: url("tempest.jpg") no-repeat center center fixed;
  width: 100vw;
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.form-control {
  color: #636161 !important;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 0 !important;
  /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: relative;
  box-shadow: inset 2px 2px 5px #b8b9be;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.collapsible-wide-dropdown ul {
  list-style: none;
  padding-left: 28px !important;
  margin-bottom: 0;
}

.collapsible-wide-dropdown ul>a {
  margin-left: -20px;
}

.collapsible-wide-dropdown>.dropdown-menu.show>.nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.dashboard-container {
  margin-left: auto !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  background-color: #f3f3f3 !important;
  border: 1px solid lightgray;
}

.dashboard-heading {
  margin-bottom: 3rem;
}

.dashboard-content {
  max-width: 95%;
  padding: 2.5rem;
  margin-top: 2rem;
  margin-left: 2rem !important;
  background-color: #fff !important;
  box-shadow: inset 3px 2px 1px #f3f3f3;
}

@media (max-width: 700px) {
  .nav-link {
    padding-left: 2px !important;
    font-size: 0.8rem;
  }
}

/* theme overrides */

table {
  color: rgb(99, 97, 97) !important;
}

h1,
h4,
p {
  color: rgb(99, 97, 97) !important;
}

.text-secondary {
  color: rgb(99, 97, 97) !important;
}

.btn-primary {
  background-color: rgb(39, 205, 208) !important;
  border-color: rgb(39, 205, 208) !important;
}

.btn-outline-primary {
  border-color: rgb(39, 205, 208) !important;
  color: rgb(39, 205, 208) !important;
}

.btn-outline-primary:hover {
  background-color: rgb(39, 205, 208) !important;
  color: white !important;
}

.btn-outline-secondary {
  border-color: mediumpurple !important;
  color: mediumpurple !important;
}

.btn-outline-secondary:hover {
  color: white !important;
  background-color: mediumpurple !important;
}

.btn-outline-warning {
  color: #fdb37b !important;
  border-color: #fdb37b !important;
}

.btn-outline-warning:hover {
  color: white !important;
  background-color: #fdb37b !important;
}

.btn-warning {
  border-color: rgb(235, 157, 73) !important;
}

.btn-danger {
  background-color: rgb(255, 120, 71) !important;
  border-color: rgb(255, 120, 71) !important;
}

.btn-outline-danger {
  border-color: rgb(255, 120, 71) !important;
}

.tempo_css h1,
.tempo_css h2,
.tempo_css h3,
.tempo_css h4 {
  color: white !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.tempo_css {
  background: rgb(140, 8, 125);
  background: linear-gradient(72deg,
      rgba(140, 8, 125, 1) 0%,
      rgba(91, 9, 121, 1) 26%,
      rgba(164, 0, 255, 1) 100%);
  color: #f8f8f8 !important;
  font-family: "Quicksand", sans-serif;
}

.tempo_css p {
  font-family: "Montserrat", sans-serif;
  color: #f8f8f8 !important;
}

.neubadge {
  box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff !important;
  transition: all 0.2s ease;
  padding: 0.9rem !important;
  border-color: #d1d9e6 !important;
  background-color: #eFeGee !important;
}

.neuInternal {
  background: 0 0;

  box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff !important;
  transition: all 0.2s ease;
  background-clip: border-box;
  border: 0.0625rem solid rgba(243, 247, 250, 0.05);
  border-radius: 0.25rem;
  color: #44476a;
  padding: 15px;
  margin-bottom: 1.4rem;
}

.hoverable:hover {
  cursor: pointer;
}

.table-center-rows>tbody>tr>td {
  vertical-align: middle;
}

.hoverablerow:hover {
  cursor: pointer;
  filter: brightness(0.95);
}

.sticky-bottom-right-screen {
  position: fixed;
  bottom: 20px;
  right: 5%;
  transform: translateX(5%);
  z-index: 10;
}

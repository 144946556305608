.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 15;
    margin: 20;
}

label{
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px
}

button{
    width: 200px;
    height: 3em;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #00bcd4;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 30px;
}

.checkBox{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.datePicker{
    width: 175px;
    height: 30px;
    padding: 10px;
}

.field{
    width: 175px;
    height: 40px;
    padding: 10px;
}

.loading{
    align-self: center;
    margin-top: 20px;
    
}

.submitButton{
    width: 200px;
    height: 3em;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #00bcd4;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 30px;
}

.codes{
    display: flex;
    flex-direction: column;
}

.downloadButton{
    width: 200px;
    height: 3em;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #00bcd4;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 30px;
    align-self: center;
}

.accessCodeHeader{
    align-self: center;
    font-size: 36px;
    font-family: 'montserrat', sans-serif;
    margin-bottom: 30px;
    margin-top: 20px;
}


.thankYou{
    align-self: center;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 30px;
}

.accessCodesContainer{
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}